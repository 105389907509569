<template>
  <div v-if="alertStatus">
    <v-alert
      :type="type"
    >
      <div v-html="message"></div>
    </v-alert>
  </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
    // Options: info, success, warnings, error
        type: {
            type: String,
            default: 'info',
        },
        message: {
            type: String,
            default: " I'm a alert with a <strong>type</strong> of error",
        },
        alertStatus: {
            type: Boolean,
            default: false,
        },
    },
    setup() {},
}
</script>
