<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<!-- eslint-disable import/no-unresolved -->
<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          <div v-html="appName"></div>
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="d-flex align-center h-full pa-16 pe-0">
            <!-- triangle bg -->
            <!-- <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-light'}.png`)"
            /> -->

            <!-- tree -->
            <v-img
              height="692"
              max-width="100%"
              class="auth-3d-group"
              src="@/assets/images/login/pharmacist.png"
            ></v-img>

            <!-- 3d character -->
            <!-- <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div> -->
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="6"
              align="center"
            >
              <v-btn

                href="https://dermcafe.ca/"
                target="_blank"
                icon
              >
                <v-img
                  contain
                  src="@/assets/images/logos/dermCafe.png"
                  height="90"
                  width="160"
                />
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              align="center"
            >
              <v-btn

                href="https://mypharmasmart.com/"
                target="_blank"
                icon
              >
                <v-img
                  src="@/assets/images/logos/pharmaSmart.png"
                  height="120"
                  width="160"
                />
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Welcome to PharmaSmart/DermCaf&eacute;! 👋🏻
                  </p>
                  <p class="mb-2">
                    Please enter your Date of Birth to access your visit details
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-text-field
                      v-model="id"
                      :disabled="queryUrl"
                      outlined
                      label="Visit ID"
                      placeholder="visitId"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                    <dob-picker
                      :dob-prop="dob"
                      @dobInput="updateDob($event)"
                    ></dob-picker>
                    <!-- <alert
                      :type="alertType"
                      :message="alertMessage"
                      :alert-status="alertStatus"
                    ></alert> -->
                    <alert
                      :type="alertType"
                      :message="alertMessage"
                      :alert-status="invalidSubmit"
                    ></alert>
                    <!-- <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field> -->

                    <!-- <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <a
                        class="ms-3"
                        href="#"
                      > Forgot Password? </a>
                    </div> -->

                    <v-btn
                      :loading="loadings.loading1"
                      :disabled="loadings.loading1"
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                      @click="login"
                    >
                      Verify
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import DobPicker from '@/components/UI/DobPicker.vue'
import { useVisitStore } from '@/stores/Visit.js'
import Alert from '@/components/UI/Alert.vue'

export default {
    name: 'Login',
    components: {
        DobPicker,
        Alert,
    },
    setup() {
        const loadings = ref({
            loading1: false,
        })
        const triggerLoading = loadingIndex => {
            loadings.value[loadingIndex] = true

            setTimeout(() => {
                loadings.value[loadingIndex] = false
            }, 3000)
        }
        const isPasswordVisible = ref(false)
        const visitStore = useVisitStore()

        // visitStore.setPatientData()

        const email = ref('')
        const password = ref('')

        return {
            loadings,
            triggerLoading,
            visitStore,
            isPasswordVisible,
            email,
            password,

            // Icons
            icons: {
                mdiEyeOutline,
                mdiEyeOffOutline,
            },

            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        }
    },
    data() {
        return {
            check: false,
            alertType: 'error',
            alertMessage: 'Verification <strong>failed</strong>. Please try again.',
            visitId: '',
            dob: this.$route.query?.dob || '',
            id: this.$route.query?.id || (localStorage.getItem('visitId') ? localStorage.getItem('visitId') : '') || '',
            redirect: this.$route.query?.redirect || 'home',
            visitData: {},
            invalidSubmit: false,
        }
    },
    computed: {
        // alertStatus() {
        //     if (this.check) {
        //         if (this.validEntry) {
        //             return false
        //         }

        //         return true
        //     }

        //     return false
        // },
        queryUrl() {
            return !!this.$route.query.id
        },
        form() {
            const data = {
                valid: false,
                id: this.id,
                fileName: `rx-${this.id}.pdf`,
                pharmacyName: this.pharmacyName,
                faxNumber: this.faxNumber,
                dob: this.dob,
            }

            return data
        },
        validEntry() {
            return this.visitStore.valid
        },
    },
    watch: {
        loader() {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => {
                this[l] = false
            }, 3000)

            this.loader = null
        },
    },
    mounted() {
        if (this.$route.query?.id) {
            this.id = this.$route.query.id
            localStorage.setItem('visitId', this.$route.query.id)
        } else if (localStorage.getItem('visitId')) {
            this.id = localStorage.getItem('visitId')
        }
        this.visitStore.$reset()
        this.$root.$on('show-error-bar', () => {
            this.loadings.loading1 = false
            this.invalidSubmit = true
            setTimeout(() => {
                this.invalidSubmit = false
            }, 3000)
        })
    },
    methods: {
        async login() {
            this.triggerLoading('loading1')
            await this.visitStore.setVisit({ id: this.id, dob: this.dob }).then(() => {
                this.check = true
            })

            this.$router.push({ name: `${this.redirect}` })
        },
        updateDob(dob) {
            this.dob = dob
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
